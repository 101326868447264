@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

:root {
  /*================================== FONT-SIZES ================================================ */
  --font-xxs: 16px;
  --font-xs: 18px;
  --font-sm: 20px;
  --font-md: 22px;
  --font-lg: 30px;
  --font-xl: 35px;
  --font-xxl: 39px;
  --font-3xl: 49px;
  --font-4xl: 70px;
  --font-25: 25px;

  /*================================== COLOR-CLASS ================================================ */
  --color-white: #fafafa;
  --color-black: #000000;
  --color-red: #ff5101;
  --color-gradient: linear-gradient(180deg, #fed710 0%, #ff5001 100%);
}

/*====================================== Media-queries-start ======================================== */
@media (max-width: 1399.98px) {
  :root {
    /*================================== FONT-SIZES ================================================ */
    --font-xxs: 16px;
    --font-xs: 18px;
    --font-sm: 20px;
    --font-25: 22px;
    --font-md: 22px;
    --font-lg: 28px;
    --font-xl: 32px;
    --font-xxl: 35px;
    --font-3xl: 45px;
    --font-4xl: 60px;
  }
}

@media (max-width: 1199.98px) {
  :root {
    /*================================== FONT-SIZES ================================================ */
    --font-xxs: 15px;
    --font-xs: 16px;
    --font-sm: 18px;
    --font-25: 20px;
    --font-md: 20px;
    --font-lg: 28px;
    --font-xl: 32px;
    --font-xxl: 35px;
    --font-3xl: 40px;
    --font-4xl: 50px;
  }
}

@media (max-width: 991.98px) {
  :root {
    /*================================== FONT-SIZES ================================================ */
    --font-xxs: 16px;
    --font-xs: 18px;
    --font-sm: 20px;
    --font-25: 22px;
    --font-md: 22px;
    --font-lg: 28px;
    --font-xl: 32px;
    --font-xxl: 35px;
    --font-3xl: 45px;
    --font-4xl: 60px;
  }
}

@media (max-width: 767.98px) {
  :root {
    /*================================== FONT-SIZES ================================================ */
    --font-xxs: 15px;
    --font-xs: 16px;
    --font-sm: 18px;
    --font-md: 20px;
    --font-lg: 28px;
    --font-xl: 32px;
    --font-25: 20px;
    --font-xxl: 35px;
    --font-3xl: 40px;
    --font-4xl: 50px;
  }
}

@media (max-width: 575.98px) {
  :root {
    /*================================== FONT-SIZES ================================================ */
    --font-xxs: 14px;
    --font-xs: 15px;
    --font-sm: 17px;
    --font-md: 19px;
    --font-lg: 25px;
    --font-xl: 30px;
    --font-xxl: 32px;
    --font-3xl: 35px;
    --font-4xl: 45px;
    --font-25: 18px;
  }
}

@font-face {
  font-family: Agnostic;
  src: url("./components//assets//font/Agnostic.otf");
}

html,
body {
  font-family: Agnostic;
  background-color: var(--color-black);
  overflow-x: hidden;
}

.ff-poppins {
  font-family: "poppins" !important;
}

/*====================================== FONT-SIZE CLASS START ======================================= */
.font-xxs {
  font-size: var(--font-xxs) !important;
}

.font-xs {
  font-size: var(--font-xs) !important;
}

.font-sm {
  font-size: var(--font-sm) !important;
}

.font-md {
  font-size: var(--font-md) !important;
}

.font-lg {
  font-size: var(--font-lg) !important;
}

.font-xl {
  font-size: var(--font-xl) !important;
}

.font-xxl {
  font-size: var(--font-xxl) !important;
}

.font-3xl {
  font-size: var(--font-3xl) !important;
}

.font-4xl {
  font-size: var(--font-4xl) !important;
}

.font-25 {
  font-size: var(--font-25) !important;
}

/*====================================== FONT-SIZE CLASS END ======================================= */
/*====================================== FONT-WEIGHT SIZE CLASS START ============================== */

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

/*====================================== FONT-WEIGHT SIZE CLASS END ========================================= */
/*====================================== COLOR CLASS START =================================================== */

.text-gradient {
  background: var(--color-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-white {
  color: var(--color-white) !important;
}

.text-black {
  color: var(--color-black) !important;
}

.ff-roboto {
  font-family: "Poppins", sans-serif !important;
}

/*===================================== COLOR CLASS END ======================================================== */
/*===================================== BACKGROUND-COLOR CLASS START =========================================== */
.bg-white {
  background-color: var(--color-white) !important;
}

.bg-black {
  background-color: var(--color-black) !important;
}

/*================================== BACKGROUND-COLOR CLASS END ================================================ */
/*================================== OPACITY CLASS START ========================================================*/

.opacity-07 {
  opacity: 0.7 !important;
}

/*================================== OPACITY CLASS END ========================================================*/
/* ================================== COMMON CLASS START  ================================================== */
.main-heading {
  font-size: var(--font-4xl);
  font-weight: 400;
}

.heading {
  font-size: var(--font-3xl);
  font-weight: 400;
}

.sub-heading {
  font-size: var(--font-xl);
  font-weight: 400;
}

.small-heading {
  font-size: var(--font-lg);
  font-weight: 400;
}

.para-common {
  font-size: var(--font-xs);
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.hero-para {
  font-size: var(--font-md);
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.common-btn {
  font-size: var(--font-sm);
  font-weight: 400;
  color: var(--color-white);
  background: var(--color-gradient);
  border: 0;
  border-radius: 54px;
  padding: 13px 25px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.common-btn:hover {
  opacity: 0.8;
  color: var(--color-white);
}

.cursor-pointer {
  cursor: pointer;
}

/* ================================== COMMON CLASS END ================================================== */

/* NAVBAR-CSS */
.overlay-text {
  font-size: 21px;
}

.hero-header-wrapper {
  min-height: 100vh !important;
}

.navbar-wrapper {
  position: fixed;
  z-index: 32;
  top: 0;
  background: #201819de;
}

.navbar-wrapper .nav-list-items a {
  color: var(--color-white);
  text-decoration: none;
  position: relative;
  font-size: var(--font-xs);
  font-weight: 400;
}

.navbar-wrapper .nav-list-items a::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--color-white);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  border-radius: 10px;
}

.connect-wallet {
  background-color: var(--color-light-blue);
  border-radius: 40px;
  color: var(--color-white);
  padding: 12px 24px;
  font-size: var(--font-sm);
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.connect-wallet:hover,
.mint-btn:hover {
  -webkit-box-shadow: 0 0 10px var(--color-light-blue),
    0 0 20px var(--color-light-blue), 0 0 20px #fff inset;
  box-shadow: 0 0 10px var(--color-light-blue), 0 0 20px var(--color-light-blue),
    0 0 20px #fff inset;
  color: var(--color-white);
}

.navbar-wrapper .nav-list-items a:hover::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.navbar-wrapper .nav-list-items a:hover {
  color: var(--color-white);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* HAMBURGER ICON STYLE  */

@media (max-width: 991.98px) {
  /* NAVBAR OVERLAY TOGGLE */

  .navbarwrapper {
    position: relative;
    width: 29px;
    cursor: pointer;
    height: 25px;
    z-index: 15;
  }

  .first {
    background-color: var(--color-white);
    width: 100%;
    height: 3px;
    position: absolute;
    border-radius: 20px;
    right: 0;
  }

  .second {
    background-color: var(--color-white);
    width: 70%;
    height: 3px;
    position: absolute;
    right: 0;
    border-radius: 20px;
    margin-top: 10px;
  }

  .third {
    background-color: var(--color-white);
    width: 100%;
    height: 3px;
    position: absolute;
    right: 0;
    border-radius: 20px;
    margin-top: 20px;
  }

  .animate .second {
    opacity: 0;
  }

  .animate .first {
    top: 11px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 1;
  }

  .animate .third {
    top: -8px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 1;
  }
}

@media (max-width: 991.98px) {
  .overlay-nav {
    -webkit-transition: 0.7s ease !important;
    -o-transition: 0.7s ease !important;
    transition: 0.7s ease !important;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    background-image: var(--color-gradient);
    width: 100%;
    height: 100%;
    pointer-events: none;
    -webkit-backdrop-filter: blur(80px);
    backdrop-filter: blur(80px);
  }

  .fix-screen-height {
    height: 100vh;
    overflow: hidden;
  }
}

.overlay-active {
  z-index: 10;
  -webkit-transition: 0.7s ease !important;
  -o-transition: 0.7s ease !important;
  transition: 0.7s ease !important;
  opacity: 1;
  pointer-events: all;
  background: var(--color-black) !important;
}

.object-fit {
  -o-object-fit: contain;
  object-fit: contain;
}

.overlay-nav li {
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  list-style-type: none;
}

.overlay-active ul li:nth-child(1) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(2) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(3) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(4) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(5) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(6) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(7) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(8) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.nav-logo {
  max-width: 100px;
  width: 100%;
}

@-webkit-keyframes animate_links {
  from {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 1;
  }
}

@keyframes animate_links {
  from {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 1;
  }
}

.h-screen {
  height: 100vh;
}

/* HERO-SECTION */
.hero-section {
  background: url("./components//assets/img/hero-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* display: flex;
  flex-direction: column;
  justify-content: end; */
}

/* TEAM-SECTION */
.team {
  background: url("./components/assets/img/team-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.team-card-text {
  position: absolute;
  bottom: 0;
  color: var(--color-white);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
}

.team-card {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.team-card:hover {
  -webkit-box-shadow: 0px 4px 41px 0px #fed71080;
  box-shadow: 0px 4px 41px 0px #fed71080;
}

.icon-hover {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
}

.icon-hover:hover {
  -webkit-box-shadow: 0px 0px 20px 5px #fed110;
  box-shadow: 0px 0px 20px 5px #fed110;
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.footer-img-1 {
  position: absolute;
  bottom: 0;
  max-width: 300px;
  width: 100%;
}

.footer-img-2 {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 300px;
  width: 100%;
}

/* RARITY-SECTION-CSS */
.rarity-card {
  max-width: 422px;
  padding: 25px;
  border-radius: 18px;
  border: 1px solid #ff5802;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin: auto;
  min-height: 600px;
}

.rarity-card:hover {
  -webkit-box-shadow: 0px 0px 30px 3px #ff5602b4;
  box-shadow: 0px 0px 30px 3px #ff5602b4;
}

.rarity-btn {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#1b1b1b),
    to(#090909)
  );
  background: -o-linear-gradient(top, #1b1b1b 0%, #090909 100%);
  background: linear-gradient(180deg, #1b1b1b 0%, #090909 100%);
  color: var(--color-white);
  padding: 12px 25px;
  border: 1px solid transparent;
}

.rarity-btn-active {
  background: var(--color-gradient);
}

/* ROYALITY-SECTION */
.royality {
  background: url("./components//assets/img/royality-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.royality-card {
  background: -o-linear-gradient(
    326.1deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.03) 101.73%
  );
  background: linear-gradient(
    123.9deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.03) 101.73%
  );
  border: 1.09px solid var(--color-red);
  padding: 30px;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(54.4328px);
  backdrop-filter: blur(54.4328px);
  padding-bottom: 0;
  position: relative;
}

.royality-box-1 {
  position: absolute;
  left: -10%;
  bottom: -5%;
  max-width: 200px;
  width: 100%;
}

.royality-box-2 {
  position: absolute;
  right: -12%;
  bottom: -5%;
  max-width: 250px;
  width: 100%;
}

.main_hero_img {
  overflow: hidden;
}

.hero_play_icon img {
  width: 59px;
  height: 59px;
}

.video-icon01 {
  bottom: 20px;
  left: -31px;
}

.video_modal .modal-content {
  background: linear-gradient(180deg, #ff5001 0%, #fed710 100%),
    linear-gradient(
      123.9deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.03) 101.73%
    );
  min-height: 50vh;
  min-width: 50vw;
}
.video_modal .modal-content iframe {
  min-height: 50vh;
  min-width: 49vw;
}

.modal-header .btn-close {
  opacity: 1 !important;
}

/* ---------- THE ROADMAP CSS ---------- */
.roadmap-img {
  margin-top: -150px;
  margin-bottom: -250px;
}
/* ---------- PHASE 1 CSS -------------- */
.roadmap_phase_img_1 {
  top: 290px;
  left: 310px;
}
.phase_img_1 {
  max-width: 124px;
}
.phase_heading {
  transform: translate(30%, 130%);
  font-size: 20px;
  top: 0;
}
/* ---------- PHASE 2 CSS -------------- */
.roadmap_phase_img_2 {
  top: 314px;
  left: 520px;
}
.roadmap_phase_img_2 .phase_heading,
.roadmap_phase_img_3 .phase_heading,
.roadmap_phase_img_5 .phase_heading,
.roadmap_phase_img_6 .phase_heading {
  transform: translate(26%, 130%);
}
/* ---------- PHASE 3 CSS -------------- */
.roadmap_phase_img_3 {
  top: 255px;
  right: 375px;
}
/* ---------- PHASE 4 CSS -------------- */
.roadmap_phase_img_4 {
  top: 476px;
  left: 240px;
}
.roadmap_phase_img_4 img {
  min-width: 144px;
}
.roadmap_phase_img_4 .roadmap_card {
  top: -154px;
  left: -90%;
}
/* ---------- PHASE 5 CSS -------------- */
.roadmap_phase_img_5 {
  top: 510px;
  left: 500px;
}

.roadmap_phase_img_5 .roadmap_card p {
  max-width: 411px;
}
/* ---------- PHASE 6 CSS -------------- */
.roadmap_phase_img_6 {
  top: 470px;
  left: 780px;
}

.roadmap_card {
  position: absolute;
  padding: 24px 12px;
  border-radius: 14.99px;
  min-width: 400px;
  border: 1.6px solid var(--color-red);
  background-color: black;
  -webkit-animation: zoom_in ease-in 0.7s;
  animation: zoom_in ease-in 0.7s;
  z-index: 10;
  top: -155px;
  left: -120%;
}
@-webkit-keyframes zoom_in {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes zoom_in {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.roadmap_parent {
  transform: translateX(20px);
}
.roadmap_phase_img_5 .roadmap_card {
  top: -340px;
  left: -110%;
}
.phase-side-img {
  max-width: 514.8px;
  top: -550px;
  right: 0;
}
.phase-side-img-2 {
  max-width: 435px;
  bottom: -450px;
  right: 0;
}
.phase-side-img-3 {
  max-width: 514.8px;
  top: 30px;
  left: 0px;
  transform: rotate(180deg);
}
.phase-side-img,
.phase-side-img-2,
.phase-side-img-3 {
  z-index: 0;
}

/* PRELOADER STYLES */
.preLoader {
  min-height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.preloader-logo {
  width: 170px;
  -webkit-animation: spinReturn 4s ease infinite;
  animation: spinReturn 4s ease infinite;
}

@-webkit-keyframes spinReturn {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinReturn {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btn-royality {
  font-size: var(--font-sm);
  font-weight: 400;
  color: var(--color-white);
  background: var(--color-gradient);
  border: 1px solid transparent;
  border-radius: 54px;
  padding: 13px 25px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.btn-royality:hover {
  opacity: 0.8;
  color: var(--color-white);
}

.team-card {
  border-radius: 14px;
  overflow: hidden;
  position: relative;
}

.team-card::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #131212e0 25%, rgba(89, 3, 3, 0) 50%);
}

.team-card img {
  border-radius: 14px;
  overflow: hidden;
  -o-object-fit: cover;
  object-fit: cover;
}

.openZooLink {
  color: #fed110;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.openZooLink:hover {
  color: #fed210bb;
}

/* ===================================== SCROLL TO TOP CSS ===================================== */
.scroll-to-top div {
  position: fixed;
  cursor: pointer;
  background-color: var(--color-red);
  font-size: 20px;
  bottom: 20px;
  right: 20px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  -webkit-transition: 0.9s;
  -o-transition: 0.9s;
  transition: 0.9s;
  border-radius: 50%;
  border: 0.75px solid transparent;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.scroll-to-top div:hover {
  color: var(--white);
  background-color: var(--color-black);
  box-shadow: inset 0 0 0 2em var(--color-black);
  border: 0.75px solid var(--color-white);
}
.scroll-to-top div svg path {
  transition: all 0.2s ease-in-out;
}
.scroll-to-top div:hover svg path {
  fill: var(--color-white);
}
.scroll-to-top div {
  animation: float 2.4s infinite linear;
  -webkit-animation: float 2.4s infinite linear;
}
@keyframes float {
  0% {
    transform: translatey(0px) scale(0.998);
  }

  50% {
    transform: translatey(-7px) scale(1.1023);
  }

  100% {
    transform: translatey(0px) scale(0.998);
  }
}

@media (min-width: 1400px) {
  .game-content .para-common {
    font-size: 17px !important;
  }
}
@media (min-width: 1600px) {
  .hero_cards_parent img {
    min-width: 326px;
  }
}
@media (max-width: 1599.98px) {
  .hero-header-wrapper {
    /* min-height: calc(100vh - 92px) !important; */
    height: calc(100vh - 92px) !important;
  }
}
@media (max-width: 1399.98px) {
  .video-icon01 {
    max-width: 120px;
    bottom: 80px;
    left: -21px;
  }

  .roadmap-img {
    min-width: 1196px;
    margin-left: -30px;
  }
  .roadmap_phase_img_1 {
    top: 255px;
    left: 255px;
  }
  .phase_img_1 {
    max-width: 118px;
  }
  .phase_heading {
    transform: translate(26%, 130%);
  }

  .roadmap_phase_img_2 .phase_heading,
  .roadmap_phase_img_3 .phase_heading,
  .roadmap_phase_img_5 .phase_heading,
  .roadmap_phase_img_6 .phase_heading {
    transform: translate(21%, 130%);
  }
  .roadmap_phase_img_2 {
    top: 276px;
    left: 456px;
  }

  .roadmap_phase_img_3 {
    top: 223px;
    right: 295px;
  }

  .roadmap_phase_img_4 {
    top: 412px;
    left: 182px;
  }

  .roadmap_phase_img_5 {
    top: 460px;
    left: 430px;
  }

  .roadmap_phase_img_6 {
    top: 416px;
    left: 689px;
  }
  .roadmap_parent {
    transform: translateX(0px);
  }
}
@media (max-width: 1199.98px) {
  .footer-img-1 {
    max-width: 200px;
  }

  .footer-img-2 {
    max-width: 200px;
  }

  .royality-box-1 {
    max-width: 150px;
  }

  .royality-box-2 {
    max-width: 200px;
  }

  .video-icon01 {
    max-width: 90px;
    bottom: -30px;
    left: -11px;
  }

  .roadmap-img {
    margin-top: -136px;
    margin-bottom: -210px;
    min-width: 1096px;
    margin-left: -40px;
  }
  .roadmap_phase_img_1 {
    top: 235px;
    left: 225px;
  }
  .phase_img_1 {
    max-width: 108px;
  }
  .phase_heading {
    font-size: 18px;
  }

  .roadmap_phase_img_2 {
    top: 256px;
    left: 404px;
  }

  .roadmap_phase_img_3 {
    top: 208px;
    right: 198px;
  }

  .roadmap_phase_img_4 {
    top: 386px;
    left: 156px;
  }
  .roadmap_phase_img_4 img {
    min-width: 126px;
  }

  .roadmap_phase_img_5 {
    top: 430px;
    left: 384px;
  }

  .roadmap_phase_img_5 .roadmap_card {
    top: -285px;
    left: -140%;
  }
  .roadmap_phase_img_6 {
    top: 382px;
    left: 620px;
  }
  .phase-side-img {
    top: -500px;
    max-width: 414.8px;
  }
  .phase-side-img-3 {
    top: -30px;
    max-width: 414.8px;
  }
}
@media (max-width: 991.98px) {
  .royality-box-1 {
    max-width: 120px;
  }

  .royality-box-2 {
    max-width: 150px;
  }

  .nav-logo {
    max-width: 70px;
  }

  .roadmap_img {
    order: 6;
  }

  .video-icon01 {
    max-width: 120px;
    bottom: -49px;
    left: -11px;
  }
  .roadmap-img {
    min-width: 900px;
    margin-top: -110px;
    margin-bottom: -210px;
    margin-left: -90px;
  }
  .roadmap_phase_img_1 {
    top: 195px;
    left: 135px;
  }
  .phase_img_1 {
    max-width: 100px;
  }
  .phase_heading {
    font-size: 17px;
  }
  .roadmap_card {
    left: -120%;
  }

  .roadmap_phase_img_2 {
    top: 210px;
    left: 280px;
  }

  .roadmap_phase_img_3 {
    top: 172px;
    right: 140px;
  }

  .roadmap_phase_img_4 {
    top: 322px;
    left: 90px;
  }
  .roadmap_phase_img_4 img {
    min-width: 112px;
  }
  .roadmap_phase_img_4 .phase_heading {
    transform: translate(21%, 130%);
  }

  .roadmap_phase_img_5 {
    top: 344px;
    left: 254px;
  }

  .roadmap_phase_img_5 .roadmap_card {
    top: -336px;
  }
  .roadmap_phase_img_5 .roadmap_card p {
    max-width: 511px;
  }

  .roadmap_phase_img_6 {
    top: 302px;
    left: 458px;
  }
  .phase-side-img-3 {
    top: 80px;
    max-width: 314.8px;
  }
}
@media (max-width: 767.98px) {
  .footer-img-1 {
    max-width: 150px;
  }

  .footer-img-2 {
    max-width: 150px;
  }

  .royality-box-1 {
    max-width: 100px;
  }

  .royality-box-2 {
    max-width: 120px;
  }

  .roadmap-img {
    min-width: 634px;
    margin-top: -100px;
    margin-bottom: -190px;
    margin-left: -42px;
  }
  .roadmap_phase_img_1 {
    top: 113px;
    left: 113px;
  }
  .phase_img_1 {
    max-width: 68px;
  }
  .phase_heading {
    font-size: 11.5px;
    transform: translate(24%, 112%);
  }

  .roadmap_phase_img_2 {
    top: 127px;
    left: 216px;
  }

  .roadmap_phase_img_3 {
    top: 100px;
    right: 110px;
  }
  .roadmap_card {
    min-width: 341px;
    top: -184px;
    left: -160px;
  }

  .roadmap_phase_img_1 .roadmap_card {
    left: -100px;
  }
  .roadmap_card h2 {
    font-size: 28px !important;
  }
  .roadmap_card p {
    font-size: 16px !important;
  }

  .roadmap_phase_img_4 {
    top: 200px;
    left: 84px;
  }
  .roadmap_phase_img_4 img {
    min-width: 80px;
  }

  .roadmap_phase_img_5 {
    top: 222px;
    left: 202px;
  }
  .roadmap_phase_img_5 .roadmap_card {
    top: -286px;
    left: -190%;
  }
  .roadmap_phase_img_5 .roadmap_card p {
    max-width: 441px;
  }

  .roadmap_phase_img_6 {
    top: 192px;
    left: 343px;
  }
  .hero-header-wrapper {
    height: 100% !important;
    min-height: calc(100vh - 92px) !important;
  }
}
@media (max-width: 575.98px) {
  .footer-img-1 {
    max-width: 100px;
    z-index: -1;
  }

  .footer-img-2 {
    max-width: 100px;
    z-index: -1;
  }

  .royality-box-1 {
    max-width: 80px;
  }

  .royality-box-2 {
    max-width: 100px;
  }

  .footer-img-1 {
    max-width: 100px;
  }

  .footer-img-2 {
    max-width: 100px;
  }

  .rarity-card {
    min-height: unset;
  }

  .hero_play_icon {
    width: 42px;
    height: 42px;
  }

  .video-icon01 {
    max-width: 70px;
    bottom: -29px;
    left: 5px;
  }
  .video_modal .modal-content,
  .video_modal .modal-content iframe {
    min-height: 30vh;
  }

  .roadmap-img {
    min-width: 111vw;
    max-width: 375px !important;
    margin-top: -60px;
    margin-bottom: -100px;
    margin-left: -25px;
  }
  .roadmap_phase_img_1 {
    top: 31%;
    left: 21%;
  }
  .phase_img_1 {
    max-width: 42px;
  }
  .phase_heading {
    font-size: 7px;
    transform: translate(25%, 125%);
  }

  .mobile_phase_img_1 {
    max-width: 150px;
  }
  .phase_heading {
    font-size: 18px;
    transform: translate(3%, 170%);
  }
  .roadmap_card {
    top: -155px;
    left: 0px;
    min-width: 300px;
    padding: 20px 10px;
  }
  .mobile-phase-right-side .roadmap_card {
    left: unset;
    right: 0;
  }
  .mobile_roadmap_phase_img_5 .roadmap_card {
    top: -300px !important;
  }
  .mobile_roadmap_phase_img_5 .roadmap_card p {
    max-width: 274px;
  }

  .phase-side-img {
    max-width: 284.8px;
    top: -340px;
  }

  .scroll-to-top div {
    width: 38px;
    height: 38px;
  }
}
@media (max-width: 399.98px) {
  .mobile_roadmap_phase_img_5 .roadmap_card {
    left: -4px !important;
  }
}
@media (max-width: 374.98px) {
  .roadmap_card {
    top: -185px !important;
  }
}
@media (max-width: 500px) {
  .footer-img-1 {
    display: none;
  }

  .footer-img-2 {
    display: none;
  }

  .icon-hover {
    width: 40px;
  }
}
